<template>
  <app-form-group :label="data.label" :required="data.required">
    <template #additional v-if="data.tooltip">
      <app-tooltip>
        <template #icon>
          <img src="@/assets/img/tooltip-icon.svg" alt="tooltip">
        </template>
        <template #content>
          <p>{{ data.tooltip }}</p>
        </template>
      </app-tooltip>
    </template>
    <app-cells position="start" :indent="false">
      <app-radio
        v-for="(radio, index) in data.options"
        :key="index"
        v-model="data.value"
        :value="radio"
        :checked="data.value"
        :label="radio"
        :name="data.id"
      />
    </app-cells>
    <template #error>
      <div v-if="$v.data.value.$dirty && !$v.data.value.required">Обязательное поле</div>
    </template>
  </app-form-group>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'Radio',
  props: ['data'],
  model: {
    prop: 'data.value',
    event: 'change'
  },
  validations() {
    return {
      data: {
        value: { required: requiredIf(model => { return model.required }) }
      }
    }
  }
}
</script>
