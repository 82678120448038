<template>
  <app-form-group :label="data.label" :required="data.required">
    <template #additional v-if="data.tooltip">
      <app-tooltip>
        <template #icon>
          <img src="@/assets/img/tooltip-icon.svg" alt="tooltip">
        </template>
        <template #content>
          <p>{{ data.tooltip }}</p>
        </template>
      </app-tooltip>
    </template>
    <v-select
      v-model="data.value"
      :id="data.id"
      :options="data.options"
      :searchable="false"
      :clearable="true"
      :placeholder="data.placeholder"
      class="select"
      :class="{ 'select--error': $v.data.value.$error }"
    >
      <template slot="open-indicator">
        <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
          <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
        </svg>
      </template>
      <template slot="option" slot-scope="option">
        <div class="select__item">{{ option.label }}</div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="d-center">{{ option.label }}</div>
      </template>
    </v-select>
    <template #error>
      <div v-if="$v.data.value.$dirty && !$v.data.value.required">Обязательное поле</div>
    </template>
  </app-form-group>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'Select',
  props: ['data'],
  model: {
    prop: 'data.value',
    event: 'change'
  },
  validations() {
    return {
      data: {
        value: { required: requiredIf(model => { return model.required }) }
      }
    }
  },
}
</script>
