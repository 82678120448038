<template>
  <h2 class="title title--indent">{{ data.label }}</h2>
</template>

<script>
export default {
  name: 'Title',
  props: ['data']
}
</script>
