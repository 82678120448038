<template>
  <app-form-group :label-for="data.id" :label="data.label" :required="data.required">
    <template #additional v-if="data.tooltip">
      <app-tooltip>
        <template #icon>
          <img src="@/assets/img/tooltip-icon.svg" alt="tooltip">
        </template>
        <template #content>
          <p>{{ data.tooltip }}</p>
        </template>
      </app-tooltip>
    </template>
    <app-input
      v-model="data.value"
      @blur.native="$emit('input', $event.target.value)"
      @input.native="onCheckDate"
      v-mask="'99.99.9999 99:99'"
      :id="data.id"
      :name="data.id"
      :error="$v.data.value.$error || $v.date_age.$invalid"
      placeholder="дд.мм.гггг чч:мм"
      autocomplete="off"
    />
    <template #error>
      <div v-if="$v.data.value.$dirty && !$v.data.value.required">Обязательное поле</div>
      <div v-if="$v.data.value.$dirty && !$v.date_age.between">Некорректная дата</div>
      <div v-if="$v.data.value.$dirty && !$v.data.value.underscorePresent">Заполните поле полностью</div>
    </template>
  </app-form-group>
</template>

<script>
import { between, requiredIf } from 'vuelidate/lib/validators'
import { differenceInCalendarYears, parse } from 'date-fns'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'DateTime',
  props: ['data'],
  data() {
    return {
      date_age: 20
    }
  },
  validations() {
    return {
      data: {
        value: { required: requiredIf(model => { return model.required }), underscorePresent }
      },
      date_age: { between: between(-30, 90) }
    }
  },
  methods: {
    onCheckDate() {
      this.$v.$touch()
      const date = parse(this.data.value.slice(0, 10), 'dd.MM.yyyy', new Date())
      this.date_age = differenceInCalendarYears(new Date(), date)
    },
  }
}
</script>
