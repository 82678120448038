<template>
  <app-form-group :label="data.label" :required="data.required">
    <template #additional v-if="data.tooltip">
      <app-tooltip>
        <template #icon>
          <img src="@/assets/img/tooltip-icon.svg" alt="tooltip">
        </template>
        <template #content>
          <p>{{ data.tooltip }}</p>
        </template>
      </app-tooltip>
    </template>
    <app-cells position="start">
      <label class="checkbox" v-for="(checkbox, index) in data.options" :key="index">
        <div class="checkbox__text">{{ checkbox }}</div>
        <input
          :id="data.id"
          type="checkbox"
          :value="checkbox"
          :name="data.id + index"
          :checked="check(checkbox)"
          @change="onCheckboxChanged($event)"
        />
        <div class="checkbox__checkmark"></div>
      </label>
    </app-cells>
    <template #error>
      <div v-if="$v.data.value.$dirty && !$v.data.value.required">Обязательное поле</div>
    </template>
  </app-form-group>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'Checkbox',
  props: ['data'],
  model: {
    prop: 'data.value',
    event: 'change'
  },
  data() {
    return {
      arr: []
    }
  },
  validations() {
    return {
      data: {
        value: { required: requiredIf(model => { return model.required }) }
      }
    }
  },
  mounted() {
    if (this.$props.data.value) {
      this.arr = this.$props.data.value
    }
  },
  methods: {
    check(checkbox) {
      if (this.$props.data.value) {
        return this.$props.data.value.includes(checkbox)
      }
      return false
    },
    onCheckboxChanged(event) {
      const index = this.arr.indexOf(event.target.value)

      if (index < 0) this.arr.push(event.target.value)
      else this.arr.splice(index, 1)

      this.$emit('change', this.arr)
    }
  }
}
</script>
