<template>
  <app-form-group :label="data.label" :required="data.required">
    <template #additional v-if="data.tooltip">
      <app-tooltip>
        <template #icon>
          <img src="@/assets/img/tooltip-icon.svg" alt="tooltip">
        </template>
        <template #content>
          <p>{{ data.tooltip }}</p>
        </template>
      </app-tooltip>
    </template>
    <vue-clip
      ref="vc"
      :options="options"
      :on-added-file="addedFile"
      :on-complete="completeFile"
      :on-sending="onSending"
      :on-removed-file="removedFile"
      class="custom-uploader"
    >
      <template slot="clip-uploader-body" v-if="innerFilePresent">
        <div class="custom-uploader__file">
          <div class="custom-uploader__body">
            <div class="custom-uploader__part">
              <a
                :href="file.file || file.customAttributes.link"
                target="_blank"
                class="custom-uploader__filename"
              >
                Посмотреть
              </a>
            </div>
            <div class="custom-uploader__part">
              <div
                v-if="file.status !== 'error' && file.status !== 'success' && file.progress"
                class="custom-uploader__progress"
              >
                {{ Math.round(file.progress) }}%
              </div>
              <button
                v-if="!readonly"
                type="button"
                class="custom-uploader__delete"
                @click="removedFile"
              >
                Удалить
              </button>
            </div>
          </div>
          <div
            class="custom-uploader__line"
            v-if="file.status !== 'error' && file.status !== 'success' && file.progress"
          >
              <span
                class="custom-uploader__indicator"
                :style="{ width: file.progress + '%' }"
              ></span>
          </div>
        </div>
        <div class="custom-uploader__error">{{ message }}</div>
      </template>
      <template slot="clip-uploader-action">
        <div>
          <div v-show="!innerFilePresent" class="custom-uploader__message dz-message">
            <img src="@/assets/img/file/staple.svg" alt=""/>
            <span>Прикрепить файл</span>
          </div>
        </div>
      </template>
    </vue-clip>
    <template #error>
      <div v-if="$v.data.value.$dirty && !$v.data.value.required">Обязательное поле</div>
    </template>
  </app-form-group>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { getAccessToken } from 'axios-jwt'

export default {
  name: 'AppUploader',
  props: {
    data: Object,
    extensions: {
      type: Array,
      default: () => ['image/*', 'application/pdf']
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'data.value',
    event: 'onAddedFile'
  },
  validations() {
    return {
      data: {
        value: { required: requiredIf(model => { return model.required }) }
      }
    }
  },
  data() {
    return {
      options: {
        url: `${process.env.VUE_APP_API_URL}files/`,
        maxFiles: {
          limit: 100,
          message: 'Вы не можете загружать больше 1 файла'
        },
        acceptedFiles: {
          extensions: this.$props.extensions,
          message: 'Неподходящий формат файла'
        },
        maxFilesize: {
          limit: 150,
          message: 'Размер файла превышает допустимый'
        },
        uploadMultiple: false
      },
      message: '',
      progress: 0,
      file: {}
    }
  },
  computed: {
    innerFilePresent() {
      return Object.keys(this.file).length !== 0
    }
  },
  mounted() {
    if (this.data.value) {
      this.file = this.$props.data.value
    }
  },
  watch: {
    file_prop(value) {
      if (value) this.file = value
    }
  },
  methods: {
    addedFile(file) {
      this.file = file
    },
    onSending(file, xhr) {
      const token = getAccessToken()
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    },
    completeFile(file, status, xhr) {
      if (file.status === 'error') {
        this.message = file.errorMessage
        this.removedFile()
        return false
      }
      const response = JSON.parse(xhr.responseText)
      file.addAttribute('link', response.file)
      this.$emit('onAddedFile', response)
      this.message = ''
    },
    removedFile() {
      this.$refs.vc.files = []
      this.file = {}
      this.$emit('onAddedFile', {})
    },
  }
}
</script>
