<template>
  <div v-html="data.value" class="content__paragraph"></div>
</template>

<script>
export default {
  name: 'Paragraph',
  props: ['data']
}
</script>
