<template>
  <app-form-group :label="data.label" :required="data.required">
    <template #additional v-if="data.tooltip">
      <app-tooltip>
        <template #icon>
          <img src="@/assets/img/tooltip-icon.svg" alt="tooltip">
        </template>
        <template #content>
          <p>{{ data.tooltip }}</p>
        </template>
      </app-tooltip>
    </template>
    <v-select
      v-model="data.value"
      :id="data.id"
      :options="org_options"
      :clearable="false"
      :filterable="false"
      label="inn"
      @search="onSearch"
      :placeholder="data.placeholder"
      class="select"
      :class="{ 'select--error': $v.data.value.$error }"
    >
      <template slot="open-indicator">
        <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
          <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </template>
      <template #no-options="{ search, searching }">
        <div v-if="searching" class="select__noty">
          По вашему запросу <em>{{ search }}</em> ничего не найдено.
        </div>
        <div v-else class="select__noty">
          Для поиска организации в справочнике, введите наименование в формате: <span>Название</span> или <span>ИНН организации</span> и выберите значение из выпадающего списка.
        </div>
      </template>
      <template slot="option" slot-scope="option">
        <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">{{ option.name }} ({{ option.address }})</div>
      </template>
    </v-select>
    <template #error>
      <div v-if="$v.data.value.$dirty && !$v.data.value.required">Обязательное поле</div>
    </template>
  </app-form-group>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { debounce } from 'lodash'

export default {
  name: 'Org',
  props: ['data'],
  model: {
    prop: 'data.value',
    event: 'change'
  },
  validations() {
    return {
      data: {
        value: { required: requiredIf(model => { return model.required }) }
      }
    }
  },
  data() {
    return {
      org_options: [],
    }
  },
  methods: {
    onSearch (search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ORG', {search}).then(response => {
        vm.org_options = response.data
        loading(false)
      })
    }, 350),
  }
}
</script>
